@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

body {
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.header {
    text-align: center;
}

a {
    text-decoration: none;
    color: white;
}

.policy__contents {
    padding: 20px 20px;
    text-align: left;
}

.contact__contents {
    padding: 20px 20px;
    text-align: center;
}

.home__contents {
    padding: 20px 20px;
    align: center;
}

.home_app_simple {
    margin: 20px 20px;
}

.home_app_tail {
    padding: 20px 20px;
    background-color: #E27552;
}

.tail {
    padding: 20px 20px;
    background-color: #713D2E;
}

.navbar__logo {
    display: flex;
    align-items: center;
}

.navbar__logo img {
    color: white;
    width: 80px;
}

.navbar__menu {
    display: flex;
    list-style: none;
    padding-left: 0;
}

.navbar__menu li {
    padding: 8px 12px;
    transition: background .4s ease-out;
}

.navbar__menu li:hover{
    background-color: #d49466;
    border-radius: 4px;
}

.navlink {
    padding: 8px 12px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #263343;
    padding: 8px 12px;
}

.navbar__toggleBtn {
    display: none;
    position: absolute;
    right: 32px;
    top: 25px;
    font-size: 24px;
    color: #d49466;
}

.app_info_card {
    padding: 20px;
    margin: 20px 20px;
    background-color: #FFDBA6;
    border-radius: 4px;
}

@media screen and (max-width: 500px) {
    .navbar {
        align-items: flex-start;
        flex-direction: column;
        padding: 8px 24px;
    }

    .navbar__menu {
        display: none;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .navbar__menu li {
        width: 100%;
    }

    .navbar__toggleBtn {
        display: block;
    }

    .app_screenshot img {
        width: 80%;
    }
}
